<template>
	<div class="ysm-order-nine">
		<div class="left" align="left">
			<img src="../../assets/ysmimg/ysmorderoneimg.png" class="infoimg" width="90px" height="110px"
				style="vertical-align: middle;">
			<div>
				<h3>反馈</h3>
				<div align="left" style="margin-bottom: 0.625rem;">请查看您的管理员建议</div>

			</div>
		</div>


		<!-- 右侧 -->
		<div class="right">
			<!-- 第一部分患者信息 -->
			<div class="hzinfo" align="left">
				<h4>详细信息</h4>
				<p id="neirong">
				
				</p>
			</div>
			<!-- 第二部分:上和下颌 -->
			<div class="con">
			<!-- <div>

				<div class="con">
					<h4>治疗方案 上颌和下颌</h4>
					<div class="right">
						<el-row>
							<el-col :span="24">
								<div id="new-medical-report-ctrl">
									<div class="medical-report-ctrl" align="left">
										<span style="display: inline-block;margin-bottom: 0.625rem;">请输入完整的医学检查</span>
										<br />
										<table class="container-table">
											<tbody>
												<tr>
													<td class="label-column">右</td>
													<td class="label-column-space">
														<div></div>
													</td>
													<td>
														<table class="report-table">
															<tbody>
																<tr class="select-row">
																	<td>
																		<div @click="getFind('a18',18)" id="a18"></div>
																	</td>
																	<td>
																		<div @click="getFind('a17',17)" id="a17"></div>
																	</td>
																	<td>
																		<div @click="getFind('a16',16)" id="a16"></div>
																	</td>
																	<td>
																		<div @click="getFind('a15',15)" id="a15"></div>
																	</td>
																	<td>
																		<div @click="getFind('a14',14)" id="a14"></div>
																	</td>
																	<td>
																		<div @click="getFind('a13',13)" id="a13"></div>
																	</td>
																	<td>
																		<div @click="getFind('a12',12)" id="a12"></div>
																	</td>
																	<td>
																		<div @click="getFind('a11',11)" id="a11"></div>
																	</td>
																	<td>
																		<div @click="getFind('a21',21)" id="a21"></div>
																	</td>
																	<td>
																		<div @click="getFind('a22',22)" id="a22"></div>
																	</td>
																	<td>
																		<div @click="getFind('a23',23)" id="a23"></div>
																	</td>
																	<td>
																		<div @click="getFind('a24',24)" id="a24"></div>
																	</td>
																	<td>
																		<div @click="getFind('a25',25)" id="a25"></div>
																	</td>
																	<td>
																		<div @click="getFind('a26',26)" id="a26"></div>
																	</td>
																	<td>
																		<div @click="getFind('a27',27)" id="a27"></div>
																	</td>
																	<td>
																		<div @click="getFind('a28',28)" id="a28"></div>
																	</td>
																</tr>
																<tr class="label-row">
																	<td>8</td>
																	<td>7</td>
																	<td>6</td>
																	<td>5</td>
																	<td>4</td>
																	<td>3</td>
																	<td>2</td>
																	<td>1</td>
																	<td>1</td>
																	<td>2</td>
																	<td>3</td>
																	<td>4</td>
																	<td>5</td>
																	<td>6</td>
																	<td>7</td>
																	<td>8</td>
																</tr>
																<tr class="label-row">
																	<td>8</td>
																	<td>7</td>
																	<td>6</td>
																	<td>5</td>
																	<td>4</td>
																	<td>3</td>
																	<td>2</td>
																	<td>1</td>
																	<td>1</td>
																	<td>2</td>
																	<td>3</td>
																	<td>4</td>
																	<td>5</td>
																	<td>6</td>
																	<td>7</td>
																	<td>8</td>
																</tr>
																<tr class="select-row">
																	<td>
																		<div @click="getFind('a48',48)" id="a48"></div>
																	</td>
																	<td>
																		<div @click="getFind('a47',47)" id="a47"></div>
																	</td>
																	<td>
																		<div @click="getFind('a46',46)" id="a46"></div>
																	</td>
																	<td>
																		<div @click="getFind('a45',45)" id="a45"></div>
																	</td>
																	<td>
																		<div @click="getFind('a44',44)" id="a44"></div>
																	</td>
																	<td>
																		<div @click="getFind('a43',43)" id="a43"></div>
																	</td>
																	<td>
																		<div @click="getFind('a42',42)" id="a42"></div>
																	</td>
																	<td>
																		<div @click="getFind('a41',41)" id="a41"></div>
																	</td>
																	<td>
																		<div @click="getFind('a31',31)" id="a31"></div>
																	</td>
																	<td>
																		<div @click="getFind('a32',32)" id="a32"></div>
																	</td>
																	<td>
																		<div @click="getFind('a33',33)" id="a33"></div>
																	</td>
																	<td>
																		<div @click="getFind('a34',34)" id="a34"></div>
																	</td>
																	<td>
																		<div @click="getFind('a35',35)" id="a35"></div>
																	</td>
																	<td>
																		<div @click="getFind('a36',36)" id="a36"></div>
																	</td>
																	<td>
																		<div @click="getFind('a37',37)" id="a37"></div>
																	</td>
																	<td>
																		<div @click="getFind('a38',38)" id="a38"></div>
																	</td>
																</tr>
															</tbody>
														</table>
													</td>
													<td class="label-column-space">
														<div></div>
													</td>
													<td class="label-column">左</td>
												</tr>
											</tbody>
										</table>
										<span class="jianchaitem">C =全冠义齿, PC =部分冠义齿, P = 桥体, I = 种植体, CC = 连冠义齿,Y = 嵌体,
											RT =
											根管治疗过</span>
										<br />
										<span class="jianchaitem">i.b. = 未完全萌出牙, AG = 先天缺失牙, V = 贴面,Ex = 已拔除牙</span>
										<br />

									</div>
								</div>
							</el-col>
						</el-row>

						<el-row style="margin-top: 0.625rem;">
							<el-col :span="13">
								<div id="new-select-teeth-ctrl">
									<div class="teeth-selection-ctrl" style="text-align: left;">
										<span>以下牙齿需要移动</span><br>
										<span v-show="yidongCompleted==false" style="color: #b25900 !important;font-weight: bold;"><img src="../../assets/ysmimg/danger.png" style="width: 16px; height: 16px;position: relative;top: 2px;" />没有选择要移动的牙齿</span>
										<table class="container-table">
											<tbody>
												<tr>
													<th align="left">患者右侧</th>
													<th align="right">患者左测</th>
												</tr>
												<tr>
													<td colspan="2">
														<table class="selection-table">
															<tbody>
																<tr class="select-row sh select-row1">
																	<td>
																		<div class="ya17" id="ya17"
																			@click="showDuiHao1('ya17',17)"></div>
																	</td>
																	<td>
																		<div id="ya16" class="ya16"
																			@click="showDuiHao1('ya16',16)"></div>
																	</td>
																	<td>
																		<div id="ya15" class="ya15"
																			@click="showDuiHao1('ya15',15)"></div>
																	</td>
																	<td>
																		<div id="ya14" class="ya14"
																			@click="showDuiHao1('ya14',14)"></div>
																	</td>
																	<td>
																		<div id="ya13" class="ya13"
																			@click="showDuiHao1('ya13',13)"></div>
																	</td>
																	<td>
																		<div id="ya12" class="ya12"
																			@click="showDuiHao1('ya12',12)"></div>
																	</td>
																	<td>
																		<div id="ya11" class="ya11"
																			@click="showDuiHao1('ya11',11)"></div>
																	</td>
																	<td>
																		<div id="ya21" class="ya21"
																			@click="showDuiHao2('ya21',21)"></div>
																	</td>
																	<td>
																		<div id="ya22" class="ya22"
																			@click="showDuiHao2('ya22',22)"></div>
																	</td>
																	<td>
																		<div id="ya23" class="ya23"
																			@click="showDuiHao2('ya23',23)"></div>
																	</td>
																	<td>
																		<div id="ya24" class="ya24"
																			@click="showDuiHao2('ya24',24)"></div>
																	</td>
																	<td>
																		<div id="ya25" class="ya25"
																			@click="showDuiHao2('ya25',25)"></div>
																	</td>
																	<td>
																		<div id="ya26" class="ya26"
																			@click="showDuiHao2('ya26',26)"></div>
																	</td>
																	<td>
																		<div id="ya27" class="ya27"
																			@click="showDuiHao2('ya27',27)"></div>
																	</td>
																</tr>
																<tr class="teeth-row sh">
																	<td>
																		<div class="ya17" id="ya17"
																			@click="showDuiHao1('ya17',17)"><img
																				src="../../assets/ysmimg/Zaehne_1.png" />
																		</div>
																	</td>
																	<td>
																		<div class="ya16" id="ya16"
																			@click="showDuiHao1('ya16',16)"><img
																				src="../../assets/ysmimg/Zaehne_2.png" />
																		</div>
																	</td>
																	<td>
																		<div class="ya15" id="ya15"
																			@click="showDuiHao1('ya15',15)"><img
																				src="../../assets/ysmimg/Zaehne_3.png" />
																		</div>
																	</td>
																	<td>
																		<div class="ya14" id="ya14"
																			@click="showDuiHao1('ya14',14)"><img
																				src="../../assets/ysmimg/Zaehne_4.png" />
																		</div>
																	</td>
																	<td>
																		<div class="ya13" id="ya13"
																			@click="showDuiHao1('ya13',13)"><img
																				src="../../assets/ysmimg/Zaehne_5.png" />
																		</div>
																	</td>
																	<td>
																		<div class="ya12" id="ya12"
																			@click="showDuiHao1('ya12',12)"><img
																				src="../../assets/ysmimg/Zaehne_6.png" />
																		</div>
																	</td>
																	<td>
																		<div class="ya11" id="ya11"
																			@click="showDuiHao1('ya11',11)"><img
																				src="../../assets/ysmimg/Zaehne_7.png" />
																		</div>
																	</td>
																	<td>
																		<div class="ya21" id="ya21"
																			@click="showDuiHao2('ya21',21)"><img
																				src="../../assets/ysmimg/Zaehne_8.png" />
																		</div>
																	</td>
																	<td>
																		<div class="ya22" id="ya22"
																			@click="showDuiHao2('ya22',22)"><img
																				src="../../assets/ysmimg/Zaehne_9.png" />
																		</div>
																	</td>
																	<td>
																		<div class="ya23" id="ya23"
																			@click="showDuiHao2('ya23',23)"><img
																				src="../../assets/ysmimg/Zaehne_10.png" />
																		</div>
																	</td>
																	<td>
																		<div class="ya24" id="ya24"
																			@click="showDuiHao2('ya24',24)"><img
																				src="../../assets/ysmimg/Zaehne_11.png" />
																		</div>
																	</td>
																	<td>
																		<div class="ya25" id="ya25"
																			@click="showDuiHao2('ya25',25)"><img
																				src="../../assets/ysmimg/Zaehne_12.png" />
																		</div>
																	</td>
																	<td>
																		<div class="ya26" id="ya26"
																			@click="showDuiHao2('ya26',26)"><img
																				src="../../assets/ysmimg/Zaehne_13.png" />
																		</div>
																	</td>
																	<td>
																		<div class="ya27" id="ya27"
																			@click="showDuiHao2('ya27',27)"><img
																				src="../../assets/ysmimg/Zaehne_14.png" />
																		</div>
																	</td>
																</tr>
																<tr class="teeth-row xh">
																	<td>
																		<div class="ya47"
																			@click="showDuiHao4('ya47',47)"><img
																				src="../../assets/ysmimg/Zaehne_15.png" />
																		</div>
																	</td>
																	<td>
																		<div class="ya46"
																			@click="showDuiHao4('ya46',46)"><img
																				src="../../assets/ysmimg/Zaehne_16.png" />
																		</div>
																	</td>
																	<td>
																		<div class="ya45"
																			@click="showDuiHao4('ya45',45)"><img
																				src="../../assets/ysmimg/Zaehne_17.png" />
																		</div>
																	</td>
																	<td>
																		<div class="ya44"
																			@click="showDuiHao4('ya44',44)"><img
																				src="../../assets/ysmimg/Zaehne_18.png" />
																		</div>
																	</td>
																	<td>
																		<div class="ya43"
																			@click="showDuiHao4('ya43',43)"><img
																				src="../../assets/ysmimg/Zaehne_19.png" />
																		</div>
																	</td>
																	<td>
																		<div class="ya42"
																			@click="showDuiHao4('ya42',42)"><img
																				src="../../assets/ysmimg/Zaehne_20.png" />
																		</div>
																	</td>
																	<td>
																		<div class="ya41"
																			@click="showDuiHao4('ya41',41)"><img
																				src="../../assets/ysmimg/Zaehne_21.png" />
																		</div>
																	</td>
																	<td>
																		<div class="ya31"
																			@click="showDuiHao3('ya31',31)"><img
																				src="../../assets/ysmimg/Zaehne_22.png" />
																		</div>
																	</td>
																	<td>
																		<div class="ya32"
																			@click="showDuiHao3('ya32',32)"><img
																				src="../../assets/ysmimg/Zaehne_23.png" />
																		</div>
																	</td>
																	<td>
																		<div class="ya33"
																			@click="showDuiHao3('ya33',33)"><img
																				src="../../assets/ysmimg/Zaehne_24.png" />
																		</div>
																	</td>
																	<td>
																		<div class="ya34"
																			@click="showDuiHao3('ya34',34)"><img
																				src="../../assets/ysmimg/Zaehne_25.png" />
																		</div>
																	</td>
																	<td>
																		<div class="ya35"
																			@click="showDuiHao3('ya35',35)"><img
																				src="../../assets/ysmimg/Zaehne_26.png" />
																		</div>
																	</td>
																	<td>
																		<div class="ya36"
																			@click="showDuiHao3('ya36',36)"><img
																				src="../../assets/ysmimg/Zaehne_27.png" />
																		</div>
																	</td>
																	<td>
																		<div class="ya37"
																			@click="showDuiHao3('ya37',37)"><img
																				src="../../assets/ysmimg/Zaehne_28.png" />
																		</div>
																	</td>
																</tr>
																<tr class="select-row xh select-row1">
																	<td>
																		<div class="ya47" id="ya47"
																			@click="showDuiHao4('ya47',47)"></div>
																	</td>
																	<td>
																		<div class="ya46" id="ya46"
																			@click="showDuiHao4('ya46',46)"></div>
																	</td>
																	<td>
																		<div class="ya45" id="ya45"
																			@click="showDuiHao4('ya45',145)"></div>
																	</td>
																	<td>
																		<div class="ya44" id="ya44"
																			@click="showDuiHao4('ya44',44)"></div>
																	</td>
																	<td>
																		<div class="ya43" id="ya43"
																			@click="showDuiHao4('ya43',43)"></div>
																	</td>
																	<td>
																		<div class="ya42" id="ya42"
																			@click="showDuiHao4('ya42',42)"></div>
																	</td>
																	<td>
																		<div class="ya41" id="ya41"
																			@click="showDuiHao4('ya41',41)"></div>
																	</td>
																	<td>
																		<div class="ya31" id="ya31"
																			@click="showDuiHao3('ya31',31)"></div>
																	</td>
																	<td>
																		<div class="ya32" id="ya32"
																			@click="showDuiHao3('ya32',32)"></div>
																	</td>
																	<td>
																		<div class="ya33" id="ya33"
																			@click="showDuiHao3('ya33',33)"></div>
																	</td>
																	<td>
																		<div class="ya34" id="ya34"
																			@click="showDuiHao3('ya34',34)"></div>
																	</td>
																	<td>
																		<div class="ya35" id="ya35"
																			@click="showDuiHao3('ya35',35)"></div>
																	</td>
																	<td>
																		<div class="ya36" id="ya36"
																			@click="showDuiHao3('ya36',36)"></div>
																	</td>
																	<td>
																		<div class="ya37" id="ya37"
																			@click="showDuiHao3('ya37',37)"></div>
																	</td>
																</tr>
															</tbody>
														</table>
													</td>
												</tr>
											</tbody>
										</table>
									</div>
								</div>
							</el-col>
						</el-row>
						<p>
						<h4>治疗类型</h4><span>{{leixing}}</span></p>
						<p>
						<h4>治疗目标</h4><span>{{mubiao}}</span></p>
						<p>
						<h4 style="display: block;!important">上传X光片</h4><span>{{xRay}}<i v-show="xRayCompleted"
								class="el-icon-check"
								style="font-weight: bolder;color: #87B87F;margin-left: 20px;"></i></span><span
							class="tip" v-show="xRayCompleted==false" style="color: #b25900 !important;font-weight: bold;">X光片丢失</span></p>
						<p>
						<h4 style="display: block;!important">上传口扫</h4><span>{{ks}}<i v-show="ksCompleted"
								class="el-icon-check"
								style="font-weight: bolder;color: #87B87F;margin-left: 20px;"></i></span><span
							class="tip" v-show="ksCompleted==false" style="color: #b25900 !important;font-weight: bold;">并非所有扫描都以上传</span></p>
						<p>
						<h4 style="display: block;!important">上传口内照片</h4><span>{{kn}}<i v-show="knCompleted"
								class="el-icon-check"
								style="font-weight: bolder;color: #87B87F;margin-left: 20px;"></i></span><span
							class="tip" v-show="knCompleted==false" style="color: #b25900 !important;font-weight: bold;">并非所有照片都以上传</span></p>
						<p>
						<h4 style="display: block;!important">上传患者照片</h4><span>{{mx}}<i v-show="mxCompleted"
								class="el-icon-check"
								style="font-weight: bolder;color: #87B87F;margin-left: 20px;"></i></span><span
							class="tip" v-show="mxCompleted==false" style="color: #b25900 !important;font-weight: bold;">并非所有照片都以上传</span></p>
						<p>
						<h4>留&nbsp;&nbsp;&nbsp;&nbsp;言</h4><span>{{liuyan}}</span></p>
						<div align="right">
							<el-button class="back" type="primary" @click="back">返回</el-button>
							<el-button class="nextStep" type="primary" @click="nextStepOrder">下一步</el-button>
						</div>
					</div>
				</div>
			</div> -->
			<div align="right">
				<el-button class="back" type="primary" @click="back">返回</el-button>
			</div>
</div>

		</div>

	</div>
</template>

<script>
	export default {
		data: function() {
			return {
				info:'',
				patientNum: '',
				patientBirthday: '',
				patientGender: '',
				leixing: '',
				mubiao: '',
				xRay: '',
				xRayCompleted: false,
				ks: '',
				ksCompleted: false,
				kn: '',
				knCompleted: false,
				mx: '',
				mxCompleted: false,
				yidongCompleted:false,
				liuyan:'',
				totalCompleted:false,
				ruleForm: {
					oid: ''
				},
				
				zuoshanglist: [], //储的左上哪些牙要移动
				youshanglist: [], //储的右上哪些牙要移动
				youxialist: [], //存储的右下哪些牙要移动
				zuoxialist: [], //储的左下哪些牙要移动
				checkList: [],
			}
		},
		methods: {
			//下一步
			nextStepOrder() {
				console.log("订单是否通过: "+this.totalCompleted);
				if(this.totalCompleted){
					this.$store.commit("setYsmStepIndex",10);
					this.$router.push("/ysmmain/ysmorder/ysmorderten");
				}else{
					this.$alert("订单填写不完整","提示");
				}
			},
			//返回
			back(){
				this.$router.push("/ysmmain/ysmlatestorder")
			}
		},
		mounted: function() {
			this.$store.commit("setYsmStepIndex",11);
			//设置默认地址
			sessionStorage.setItem("location", "/ysmmain/ysmorder/ysmordereleven2")
			//获取当前添加的订单oid
			//获取oid
			// let oid=sessionStorage.getItem("oid");
			// if(oid==null){
			// 	this.$alert("请填写患者信息","提示");
			// 	this.$router.push("/addorder/orderone");
			// }else{
			// 	this.ruleForm.oid=oid;
			// }
			document.getElementsByClassName("fun1")[0].setAttribute("class","fun1 sub-menue1")
			document.getElementsByClassName("fun")[0].setAttribute("class","fun sub-menue")
			document.getElementsByClassName("fun2")[0].setAttribute("class","fun2 sub-menue2")
			document.getElementsByClassName("fun3")[0].setAttribute("class","fun3 sub-menue3 visible")
			//设置返回路径
			this.$store.commit("setBackLocation", "/ysmmain/ysmlatestorder");
		},
		created: function() {
			//如果是编辑订单要获取oid的值
			let oid = sessionStorage.getItem("oid");

			if (oid != "undefined" && oid != '' && oid != null) {
				this.ruleForm.oid = oid;
				this.$axios.get("/client/order/getOrderFeedback", {
					params: {
						oid: this.ruleForm.oid
					}
				}).then(res => {
					console.log(res.data.data);
					// this.info = res.data.data.info;
					$("#neirong").html(res.data.data.info);
					
					
					//哪些牙齿变成灰色
					
					
				}).catch(err => {
					console.log(err);
				});
			}else{
				this.$alert('请填写患者信息', '提示', {
					confirmButtonText: '确定',
					callback: action => {
						this.$router.push("/ysmmain/ysmorder/ysmorderone");
					}
				});
				
			}
		},
		
	}
</script>

<style scoped="scoped">
	/* 单击28颗牙齿的时候出现对号 */
	.duihao {
		background: url(../../assets/ysmimg/duihao.png);
		background-repeat: no-repeat;
		background-position: center;
		background-size: 70% 70%;
	}
	.ysm-order-nine {
		width: 80%;
		display: flex;
		margin: 0px auto;
		margin-top: 3.125rem;
		padding-bottom: 5.125rem;
		justify-content: space-around;
	}

	h3 {
		font-family: 'themixitalic';
		font-weight: 400;
		line-height: 1.1;
		color: #af9f73;
		margin-bottom: 20px;
		font-size: 24px;
	}

	.left {
		display: flex;
		width: 30rem;
		position: relative;
		justify-content: flex-start;
	}
.right{
	width: 560px;
}
	/* 按钮 */
	.nextStep {
		background-color: #af9f73 !important;
		border-color: #af9f73 !important;
		color: #fff !important;
		min-width: 7.5rem;
	}

	.back {
		min-width: 7.5rem;
		background-color: transparent;
		border-color: #af9f73 !important;
		color: #af9f73 !important;
	}

	.hzinfo span {
		font-size: 0.9375rem;
		display: inline-block;
		margin-left: 1.25rem;
	}

	/* 第二部分 */
	.con {
		position: relative;
		/* left: 9.55rem; */
		text-align: left;
	}

	/* 32颗牙 */
	.medical-report-ctrl {
		width: 100%;
	}

	.jianchaitem {
		color: #999999;
		font-size: 12px;
	}

	table {
		border: 0;
		background-color: transparent;
		width: 100%;
		border-spacing: 0;
		border-collapse: collapse;
	}

	table.container-table {
		border: solid 4px #ccc;
	}

	/* 左L右R的背景颜色 */
	table.container-table td.label-column {
		text-align: left;
		padding: 5px;
		background-color: #ccc;
	}

	/* 右R */
	table.container-table td.label-column:first-child {
		padding-right: 10px !important;
	}

	/* 左L */
	table.container-table td.label-column:last-child {
		padding-left: 10px !important;
	}

	table td {
		text-align: center !important;
		vertical-align: middle !important;
		border: 0 !important;
		padding: 0 !important;
	}

	.medical-report-ctrl table.report-table tr.select-row td div {
		height: 24px;
		line-height: 1.5rem;
		border: solid 2px #ccc;
		font-size: 13px;
		text-align: center;
		padding-top: 3px;
		cursor: pointer;
	}

	table.report-table td div {
		border-top: 0 !important;
		border-bottom: 0 !important;
		min-width: 31px;
		white-space: nowrap;
	}

	.medical-report-ctrl table.report-table td:nth-child(1) div {
		border-left: 0 !important;
		min-width: 29px;
	}

	/* table.report-table中的第二行的tr下边框褐色 */
	.medical-report-ctrl table.report-table tr:nth-child(2) td {
		border-bottom: solid 2px #333 !important;
	}

	/* 序号行的td的背景颜色 */
	.medical-report-ctrl table.report-table tr.label-row td {
		height: 15px;
		background-color: #ccc;
	}

	/* 白色隔条 */
	td.label-column-space div {
		background-color: #fff;
		width: 0.1875rem;
		border-left: 0;
		border-right: 0;
	}

	/* 最后一个td的div */
	.medical-report-ctrl table.report-table td:nth-last-child(1) div {
		border-right: 0 !important;
		min-width: 29px;
	}

	/* 竖线 */
	.medical-report-ctrl table.report-table tr.label-row td:nth-child(9) {
		border-left: solid 2px #333 !important;
	}

	/* =======================28颗牙齿============================== */
	tr {
		display: table-row;
	}

	.teeth-selection-ctrl {
		background-color: white;

	}

	.teeth-selection-ctrl table.container-table {
		border: 0;
	}

	.teeth-selection-ctrl table.selection-table {
		border: solid 1px #ccc;
		border-left-width: 2px !important;
		border-right-width: 2px !important;
	}

	.teeth-selection-ctrl table td {
		text-align: center !important;
		vertical-align: middle !important;
		background-color: #ccc;
		padding: 0 !important;

	}

	.teeth-selection-ctrl table.container-table th {
		font-size: 12px;
		color: #999;
	}

	th {
		font-weight: bold;
	}

	.teeth-selection-ctrl table.selection-table tr.select-row td:first-child div {
		border-left-color: #ccc !important;
	}

	.teeth-selection-ctrl table.selection-table tr.select-row td div {
		height: 28px;
		font-size: 22px;
		line-height: 25px;
		font-weight: bold;
		text-align: center;
		vertical-align: middle;
		border-right-color: #fff !important;
		border-left-color: #fff !important;
		color: #fff;
		background-color: #CCCCCC;
	}

	.teeth-selection-ctrl table.selection-table td div {
		min-width: 41px;
		cursor: pointer;
		border: solid 1px #ccc;

	}

	.teeth-selection-ctrl table.selection-table td * {
		box-sizing: content-box;
	}

	.teeth-selection-ctrl table.selection-table tr.teeth-row td div {
		height: 37px;
		font-size: 13px;
		text-align: center;
		background-color: #fff;
	}

	.teeth-selection-ctrl table.selection-table tr:nth-child(2) {
		border-bottom: solid 2px #CCCCCC !important;
	}

	.teeth-selection-ctrl table.selection-table td:nth-child(7) div {
		border-right-width: 3px !important;
	}

	.teeth-selection-ctrl table.selection-table td:nth-last-child(1) div {
		border-right: 0 !important;
		min-width: 38px;
	}

	.infoimg {
		width: 5.625rem;
		height: 6.875rem;
	}

	img {
		border: 0;
		width: 2.3125rem;
		height: 2.3125rem;
	}
	#hei {
		filter: grayscale(100%);
		-webkit-filter: grayscale(100%);
		-moz-filter: grayscale(100%);
		-ms-filter: grayscale(100%);
		-o-filter: grayscale(100%);
		filter: progid:DXImageTransform.Microsoft.BasicImage(grayscale=1);
		-webkit-filter: grayscale(1);
		pointer-events: none;
	}
	#neirong{
		padding-left: 50px;
	}
	.hei {
		filter: grayscale(100%);
		-webkit-filter: grayscale(100%);
		-moz-filter: grayscale(100%);
		-ms-filter: grayscale(100%);
		-o-filter: grayscale(100%);
		filter: progid:DXImageTransform.Microsoft.BasicImage(grayscale=1);
		-webkit-filter: grayscale(1);
		pointer-events: none;
	}
</style>
